import {journalData} from './journalData'
import React, {useState} from "react";
import './Journal.css'
import ReactHtmlParser from 'react-html-parser';

export default function Journal(){
    const [images, setImages] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [clicked, setClicked] = useState(null);

    var modal = document.getElementById("image-container-journal-mobile");
    var img = document.getElementById('image');
    var modalImg = document.getElementById("img01");

    function closeModal() {
        modal.style.display = "none";
        setClicked(null)
    }

    /*const toggle = index => {
        if(clicked !== -1 && images !== -1){
            document.getElementById('image-container-journal').className ='image-hide'
        }
        else
        {
           document.getElementById('image-container-journal').className ='image-default'
        }

        setImages(-1)
        setCurrentImage(0)
        clicked === index ? setClicked(null) : setClicked(index)
    }*/
    const toggleImages = index => {
        if(clicked === index) {
            setTimeout(()=>{setClicked(null)},1000)
        } else{
            if(clicked === null){
                setClicked(index)
            } else{
                setClicked(index)
                setCurrentImage(0)
                showPictures()
                setImages(-1)
            }
        }
        if(images === index){
            setCurrentImage(0)
            showPictures()
            return setImages(-1)
        }
        showPictures()
        return setImages(index)
    }
    function showPictures() {
        let className = document.getElementById('image-container-journal').className
        if(className === "image-container-show"){
            document.getElementById('homeStart').classList.add('no-overlay')
            document.getElementById('image-container-journal').className = 'image-container-hide'
            setTimeout(() => {document.getElementById('homeStart').classList.remove('no-overlay')}, 1000)
        } else {
            if( window.matchMedia("only screen and (max-width: 760px)").matches) {
                showMobileImage()
            }
            else{
                document.getElementById('homeStart').classList.add('no-overlay')
                document.getElementById('image-container-journal').className = 'image-container-show'
                setTimeout(() => {document.getElementById('homeStart').classList.remove('no-overlay')}, 1000)
            }
        }
    }
    function cycleImages(imageArray) {
        currentImage === imageArray.length - 1 ? setCurrentImage(0) : setCurrentImage(currentImage + 1)
    }

    function showMobileImage(){
        if(modal != null)
        {
            modal.style.display = "block";
        }
        else{
            modal = document.getElementById("image-container-journal-mobile");
            modal.style.display = "block";
        }
        img = document.getElementById('image');
        if(img != null)
        {
            modalImg.src = img.src;
        }
    }

    return (
        <>
            <div id='homeStart' className='left-container active'>
                <div id="journaldiv" className='journal'>
                    {journalData.map((data, index) => {
                        return <>
                            <div className='journalEntry'>
                                <div className="journalData-title">
                                    <span className="journalData-title">{data.title}</span>
                                    <span className="journalData-date">{data.date}</span>
                                    <div id="interaktion" className={
                                        clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                    </div>
                                </div>
                                <div className="journalData-title-line"></div>
                                <div className='journalEntryText'>
                                    <p className='journalData-highlight'>{ReactHtmlParser(data.highlight)}</p>
                                    <p className='journalData-text'>{ReactHtmlParser(data.text)}</p>
                                </div>
                            </div>
                        </>
                    })}
                </div>
            </div>
            <div id='image-container-journal' className="image-container-not-mobile">
                    {journalData.map((data,index) =>  {
                        if (clicked === index) {
                            const imgArray = data.images
                            if (imgArray.length > 0) {
                                return <>
                                <div className="flex">
                                    <img id="image" alt="" src={"images/journalimages/" + imgArray[currentImage]}
                                         onClick={() => cycleImages(imgArray)}/>
                                </div>
                                </>
                            }
                        }

                    })}
            </div>
            <div id='image-container-journal-mobile' className="image-container-mobile">
                <span className="close" onClick={() => closeModal()}>&times;</span>
                {journalData.map((data,index) =>  {
                    if (clicked === index) {
                        const imgArray = data.images
                        if (imgArray.length > 0) {
                            return <>
                                <div class="flex">
                                    <img id="image" alt="" src={"images/journalimages/" + imgArray[currentImage]}
                                     onClick={() => cycleImages(imgArray)}/>
                                </div>
                            </>
                        }
                    showMobileImage()
                    }

                })}
                <img className="modal-content" alt="" id="img01" />
            </div>
            </>
    )
}
