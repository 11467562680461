import './Contact.css'

export default function Contact(){

    return (
        <>
            <div id='contactstart' className={'active left-container' }>
                <div className='content'>
                    <div className='contact-information'>
                        <div className="contact-container">
                            <p className='title-text'>Lab</p>
                            <div className="contact-address-container">
                                <p className='contact-address'><nobr className='contact-address'>Fachhochschule Nordwestschweiz</nobr></p>
                                <p className='contact-address'><nobr className='contact-address'>Institut für Kunststofftechnik</nobr></p>
                                <p className='contact-address'><nobr className='contact-address'>Klosterzelgstrasse 2</nobr></p>
                                <p className='contact-address'><nobr className='contact-address'>5210 Windisch</nobr></p>
                            </div>
                        </div>
                        <div className="contact-container">
                            <p className='title-text'>Location</p>
                            <div className="contact-address-container">
                                <p className='contact-address'>KUORI GmbH</p>
                                <p className='contact-address'>Münchensteinerstr. 274a</p>
                                <p className='contact-address'>4053 Basel</p>
                                <p className='contact-address'>Switzerland</p>
                            </div>
                        </div>
                    </div>
                    <div className="contact-information">
                        <div className='contact-container'>
                            <p className='title-text'>Social Media</p>
                            <div className='social-media'>
                                <div className='socialmedia-entry'>
                                    <a className='socialmedia-link' href="https://www.instagram.com/kuori.material/?hl=de">
                                        <img className='socialmedia-image' id='instagram' src='images/socialmedia/Insta.svg'
                                             alt='Kuori Instagram'></img>
                                        <div className="logo-description">Instagram</div>
                                    </a>
                                </div>
                                <div className='socialmedia-entry-last'>
                                    <a  className='socialmedia-link' href="https://www.linkedin.com/company/kuorimaterial/mycompany/?viewAsMember=true">
                                        <img className='socialmedia-image' id='linkedin' src='images/socialmedia/LinkedIn.svg'
                                             alt='Kuori LinkedIn'></img>
                                        <div className="logo-description">LinkedIn</div>
                                    </a>
                                </div>
                                <div className='socialmedia-entry'>
                                    <a  className='socialmedia-link' href="https://www.youtube.com/watch?v=TCRrElEsI1E">
                                        <img className='socialmedia-image' id='youtube' src='images/socialmedia/Youtube.svg'
                                             alt='Kuori Youtube'></img>
                                        <div className="logo-description">Youtube</div>
                                    </a>
                                </div>
                                    <div className='socialmedia-entry-last'>
                                    <a  className='socialmedia-link' href="https://www.facebook.com/KUORI-waste-turns-material-100759235734567">
                                        <img className='socialmedia-image' id='facebook' src='images/socialmedia/Facebook.svg'
                                             alt='Kuori Facebook'></img>
                                        <div className="logo-description">Facebook</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-container">
                            <p className="title-text">E-Mail</p>
                            <div className="contact-address-container">
                                <p className="contact-address"><a className="smallFont" href="mailto:info@kuori.ch" >info@kuori.ch</a></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-content'></div>
            </div>
        </>
    )

}