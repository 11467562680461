import './Support.css'
import {FinancialSupportData, CoachingData, NominationsPricesAwardsData} from "./supportData";
import ReactHtmlParser from "react-html-parser";

export default function Support() {
    return (
        <>
            <div id='aboutStart' className={'active left-container' }>
                <div className="support-content">
                    <div className="donor-text-container">
                        <div className='supportData-highlight'>
                            Do you want to support a young, aspiring start-up in taking a step towards a more circular economy?
                        </div>
                        <div className="supportData-text">
                            Do you have exciting ideas or do you just want to talk to us about our project and a more sustainable future? Do you have some financial means that you want to invest in a fast-growing sector?<br /><br />
                            Send us an e-mail to <a href="mailto:info@kuori.ch" className="donation-text link-underline smallFont">info@kuori.ch</a>
                        </div>
                    </div>
                    <div className='Donors'>
                        <div className='support-title'>Financial Support</div>
                        <div className='donor-text-container'>
                            {FinancialSupportData.map((data, index) => {
                                return <>
                                    <p className="supportData-highlight">
                                        <a href={data.link} className="colorChange">{data.title}</a>
                                    </p>
                                    <p className="supportData-text">{data.text}</p>
                                    <br/>
                                </>
                            })}
                        </div>
                    </div>
                    <div className='Donors'>
                        <div className='support-title'>Coaching</div>
                        <div className='donor-text-container'>
                            {CoachingData.map((data, index) => {
                                return <>
                                    <p className="supportData-highlight">
                                        <a href={data.link} className="colorChange">{data.title}</a>
                                    </p>
                                    <p className="supportData-text">{data.mentor}</p>
                                    <br/>
                                </>
                            })}
                        </div>
                    </div>
                    <div className='Donors'>
                        <div className='support-title'>Nominations, Prizes & Awards</div>
                        <div className='donor-text-container'>
                            {NominationsPricesAwardsData.map((data, index) => {
                                return <>
                                    <p className="supportData-highlight">{data.title}</p>
                                    <p className="supportData-text">{ReactHtmlParser(data.subtitle)}</p>
                                    <p className="supportData-text">{ReactHtmlParser(data.text)}</p>
                                    <br/><br/>
                                </>
                            })}
                        </div>
                    </div>
                    <div className='Donors'>
                        <div className='support-title'>All Supporters</div>
                        <div className='allsupport-container'>
                            {FinancialSupportData.map((data, index) => {
                                return <>
                                    <a href={data.link}><img className="allsupporters-image" alt="" src={'images/support/financialsupport/' + data.img}/></a>
                                </>
                            })}
                            {CoachingData.map((data, index) => {
                                return <>
                                <a href={data.link}><img className="allsupporters-image" alt="" src={'images/support/coaching/' + data.img}/></a>
                                </>
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div>
            </div>

        </>
    )
}