import React from 'react';
import './Button2.css';

function Button2(props) {
    return (
        <button
                className={props.className}
                id={props.id}
            >
                {props.text}
        </button>
        );
}
export default Button2;