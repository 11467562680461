import './App.css';
import About from './components/pages/About'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Navbar from "./components/Navbar";
import Journal from "./components/pages/Journal";
import Support from "./components/pages/Support.js";
import Contact from "./components/pages/Contact";
import React from "react";

function App() {

   // <!-- Global site tag (gtag.js) - Google Analytics -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-FQ82BDKXDM"></script>
    let dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments)}
    gtag('js', new Date());

    gtag('config', 'G-FQ82BDKXDM');

  return (
      <>
          <header>
              <meta charSet="UTF-8"/>
          </header>
          <Router>
              <Navbar />
              <Switch>
                  <Route path='/about' component={About} />
                  <Route path="/Home" component={Navbar}/>
                  <Route path ='/Journal' component={Journal}/>
                  <Route path ='/Support' component={Support}/>
                  <Route path ='/Contact' component={Contact}/>
              </Switch>
          </Router>
      </>
  );
}

export default App;
