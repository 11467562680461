import React, {useState} from 'react';
import Button2  from './Button2';
import {NavLink} from 'react-router-dom';
import './Navbar.css';
import './pages/home.css';
import Slideshow from "./slideshow";
import lottie from "lottie-web";
import animation_yellow from "./lotties/kuori.json";
import animation_white from "./lotties/kuori_white.json"


function Navbar() {
    const [button, setButton] = useState(0);
    const [mobileMenu, setMobileMenu] = useState(false);
    const [kuoriAnimation, setKuoriAnimation] = useState(animation_white);
    const [smallHomeLeft, setSmallHomeLeft] = useState(false)

    let closeButton;

    function hideCloseButton() {
        closeButton.style.display = 'none';
    }
    function showCloseButton() {
        closeButton.style.display = 'block';
    }

    const clickButton = id => {
        closeButton = document.getElementById("closeButton")
        if(window.matchMedia("only screen and (max-width: 760px)").matches){
            animateBackMobileMenue()
            setTimeout(()=>{
                setMobileMenu(false)
                if(button === id){
                    if(button !== 0)
                    {
                        aniback()
                    }
                    return setButton(0);
                }
                if(id === 0 || id === 1){
                    if(button !== 0 && button !== 1)
                    {
                        aniback()
                    }
                    return setButton(0);
                }

                if(id !== 1 && button !== 1)
                {
                    setButton(id)
                    ani()
                }
            },1050)
            if(document.getElementsByClassName('mobile-home-button')[0].style.display === "none")
            {
                if(id === 1) {
                    document.getElementsByClassName('mobile-home-button')[0].style.display = "block"
                }
            }
            else
            {
                document.getElementsByClassName('mobile-home-button')[0].style.display = "none"
            }
            if(id !== 1 && button !== 1)
            {
                // Deaktivieren des Product Cycle falls dieser aktiviert war beim aufruf des Untermenues
                let displayStatus = document.getElementById('mobile-productcycle').style.display
                if(displayStatus === "block"){
                    if(document.getElementById('mobile-productcycle').className == "start-animation-productcycle")
                    {
                        document.getElementById('mobile-start-arrow').classList.add("mobile-start-arrow-startimage")
                        document.getElementById('mobile-start-arrow').classList.remove("mobile-start-arrow-backImage")
                        document.getElementById('mobile-productcycle').classList.remove("start-animation-productcycle")
                    }
                    document.getElementById('mobile-productcycle').style.display = "none";
                }
            }
        }
        else
        {
            setMobileMenu(false)
            if(button === id){
                if(button !== 0)
                {
                    aniback()
                }
                return setButton(0);
            }
            if(id === 0 || id === 1){
                if(button !== 0 && button !== 1)
                {
                    aniback()
                }
                return setButton(0);
            }

            if(id !== 1 && button !== 1)
            {
                setButton(id)
                ani()
            }
        }
    }
    function getOrder(buttonID) {
        if (button === buttonID){
            if(window.matchMedia("only screen and (max-width: 760px)").matches){
                return "order"+buttonID
            }
            else {
                return "order2"
            }
        }
        if (button === null) {
            return "order"+buttonID
        }
        let order = buttonID + 1;
        return "order"+order;
    }
    function getButton(buttonID){
        if (button === buttonID){
            if(window.matchMedia("only screen and (max-width: 760px)").matches){
                return "btn-" + buttonID + "-collapsed"
            }
            else
            {
                return "btn-expanded"
            }
        }
        if (button === 0) {
            return "btn-" + buttonID + "-default"
        }
        return "btn-" + buttonID + "-collapsed"
    }
    function setActive(side) {
        if(!window.matchMedia("only screen and (max-width: 760px)").matches){
            if(side === 'right') {
                document.getElementById('home-right').classList.add('active');
                document.getElementById('home-right').classList.remove('inactive');
                document.getElementById('home-left').classList.add('inactive');
                document.getElementById('home-left').classList.remove('active');
                setKuoriAnimation(animation_yellow)
                console.log(kuoriAnimation)
            } else {
                document.getElementById('home-left').classList.add('active');
                document.getElementById('home-left').classList.remove('inactive');
                document.getElementById('home-right').classList.add('inactive');
                document.getElementById('home-right').classList.remove('active');
                setKuoriAnimation(animation_white)
                console.log(kuoriAnimation)
            }
        }
    }

    function ani() {
        if( window.matchMedia("only screen and (max-width: 760px)").matches) {
            // Deaktivieren des Product Cycle falls dieser aktiviert war beim aufruf des Untermenues
            let displayStatus = document.getElementById('mobile-productcycle').style.display
            if(displayStatus === "block"){
                if(document.getElementById('mobile-productcycle').className == "start-animation-productcycle")
                {
                    document.getElementById('mobile-start-arrow').classList.add("mobile-start-arrow-startimage")
                    document.getElementById('mobile-start-arrow').classList.remove("mobile-start-arrow-backImage")
                    document.getElementById('mobile-productcycle').classList.remove("start-animation-productcycle")
                }
                document.getElementById('mobile-productcycle').style.display = "none";
            }
            document.getElementById("home-left").classList.add("not-visible")
            document.getElementById("home-right").classList.add("not-visible")
        }
        else{
            hideCloseButton()
            document.getElementById('home-left').className = 'animate-left';
            document.getElementById('home-left').id = 'home-left';
            document.getElementById('home-right').className = 'animate-right';
            document.getElementById('home-right').id = 'home-right';
            document.getElementById('slide-show').classList.add('not-visible');
            document.getElementsByClassName("animation-container").item(0).classList.add('not-visible');
            document.getElementById("desktopHome").style.display = "block";
            setSmallHomeLeft(true);
            setTimeout(()=>{
                document.getElementById("journal").style.display = "none";
            },1050)
            setTimeout(showCloseButton,2000)
        }
    }
    function aniback() {
        if( window.matchMedia("only screen and (max-width: 760px)").matches) {
            // Deaktivieren des Product Cycle falls dieser aktiviert war beim aufruf des Untermenues
            let displayStatus = document.getElementById('mobile-productcycle').style.display
            if(displayStatus === "block"){
                if(document.getElementById('mobile-productcycle').className == "start-animation-productcycle")
                {
                    document.getElementById('mobile-start-arrow').classList.add("mobile-start-arrow-startimage")
                    document.getElementById('mobile-start-arrow').classList.remove("mobile-start-arrow-backImage")
                    document.getElementById('mobile-productcycle').classList.remove("start-animation-productcycle")
                }
                document.getElementById('mobile-productcycle').style.display = "none";
            }
            document.getElementById("home-left").classList.remove("not-visible")
            document.getElementById("home-right").classList.remove("not-visible")
        }
        else{
            hideCloseButton()
            document.getElementById('home-left').className = 'animateBack-left';
            document.getElementById('home-left').id = 'home-left';
            document.getElementById('home-right').className = 'animateBack-right';
            document.getElementById('home-right').id = 'home-right';
            document.getElementById('slide-show').classList.remove('not-visible');
            document.getElementById('home-right').classList.add('inactive')
            document.getElementsByClassName("animation-container").item(0).classList.remove('not-visible');
            document.getElementById("desktopHome").style.display = "none";
            document.getElementById("journal").style.display = "block";
            setSmallHomeLeft(false);
        }
    }
    function mobileMenuClick() {
        if(mobileMenu){
            animateBackMobileMenue()
        }
        else
        {
            setMobileMenu(!mobileMenu)
            document.getElementById("mobile-menu").classList.add("animation-icon-mobile-menu")
            setTimeout(()=>{
                document.getElementById("mobile-menu").classList.add("fa-times")
                document.getElementById("mobile-menu").classList.remove("fa-bars")
            },250)
            setTimeout(()=>{
                document.getElementById("mobile-menu").classList.remove("animation-icon-mobile-menu")
            },1500)
        }

        console.log(mobileMenu)
    }

    function animateBackMobileMenue(){
        document.getElementById("mobile-menu-container").classList.add("back-animation-mobile-menu")
        document.getElementById("mobile-menu").classList.add("animation-icon-mobile-menu")
        setTimeout(()=>{
            setMobileMenu(!mobileMenu)
        },1050)
        setTimeout(()=>{
            document.getElementById("mobile-menu").classList.add("fa-bars")
            document.getElementById("mobile-menu").classList.remove("fa-times")
        },250)
        setTimeout(()=>{
            document.getElementById("mobile-menu-container").classList.remove("back-animation-mobile-menu")
        },1500)
        setTimeout(()=>{
            document.getElementById("mobile-menu").classList.remove("animation-icon-mobile-menu")
        },1550)
    }

    let animationContainer = React.createRef();

    React.useEffect(() => {

            const anim = lottie.loadAnimation({
                container: animationContainer.current,
                animationData:kuoriAnimation
            })
            return () => anim.destroy()
    })

    function showProductcycle(){
        let displayStatus = document.getElementById('mobile-productcycle').style.display
        document.getElementById('mobile-start-arrow').classList.add("animation-icon-mobile-menu-arrow")
        if(displayStatus === "block"){
            if(document.getElementById('mobile-productcycle').className == "start-animation-productcycle")
            {
                document.getElementById('mobile-productcycle').classList.remove("start-animation-productcycle")
            }

            document.getElementById('mobile-productcycle').classList.add("back-animation-productcycle")
            setTimeout(()=>{
                document.getElementById('mobile-start-arrow').classList.add("mobile-start-arrow-startimage")
                document.getElementById('mobile-start-arrow').classList.remove("mobile-start-arrow-backImage")
            },500)
            setTimeout(()=>{
                document.getElementById('mobile-productcycle').style.display = "none";
            },1050)
            setTimeout(()=>{
                document.getElementById('mobile-start-arrow').classList.remove("animation-icon-mobile-menu-arrow")
            },2000)
        }
        else {
            if(document.getElementById('mobile-productcycle').className == "back-animation-productcycle")
            {
                document.getElementById('mobile-productcycle').classList.remove("back-animation-productcycle")
            }
            document.getElementById('mobile-productcycle').classList.add("start-animation-productcycle")
            setTimeout(()=>{
                document.getElementById('mobile-start-arrow').classList.add("mobile-start-arrow-backImage")
                document.getElementById('mobile-start-arrow').classList.remove("mobile-start-arrow-startimage")
            },500)
            setTimeout(()=>{
                document.getElementById('mobile-start-arrow').classList.remove("animation-icon-mobile-menu-arrow")
            },2000)
            document.getElementById('mobile-productcycle').style.display = "block";
        }
    }


    return (
        <>
            <div className='mobile-menu' onClick={mobileMenuClick}>
                <i id='mobile-menu' className={'fas fa-bars'} />
            </div>
                <div id="mobile-menu-container" className={mobileMenu ? 'navbar-container open start-animation-mobile-menu' :'navbar-container'}>
                    <NavLink className={getOrder(1)} to='/' id="1" onClick={() => clickButton(1)}>
                        <Button2
                            id='journal'
                            text='Home'
                            className={getButton(1)}
                            state='default'
                        >
                        </Button2>
                    </NavLink>
                    <NavLink className={getOrder(2)} to='/about' onClick={() => clickButton(2)}>
                        <Button2
                            id='about'
                            text='About'
                            state='default'
                            className={getButton(2)}
                        >
                        </Button2>
                    </NavLink>
                    <NavLink className={getOrder(3)} to='/Support' onClick={() => clickButton(3)}>
                        <Button2
                            text='Support'
                            id='donation'
                            className={getButton(3)}
                            state='default'
                        >
                        </Button2>
                    </NavLink>
                    <NavLink className={getOrder(4)} to='/Contact' onClick={() => clickButton(4)}>
                        <Button2
                            text='Contact'
                            id='contact'
                            className={getButton(4)}
                            state='default'
                        >
                        </Button2>
                    </NavLink>
                    <NavLink id='home' className={getOrder(5)} to={'/'} onClick={() => clickButton(5)}>
                        <Button2
                            text='Home'
                            id='home'
                            className={getButton(5)}
                            state='default'
                        >
                        </Button2>
                    </NavLink>
                    <NavLink id='closeButton' to={'/'} onClick={() => clickButton(button)}>
                    </NavLink>
                </div>
            <div id='home-left' className='left-container active' onClick={() => setActive("left")}>
            <div className="animation-container" ref={animationContainer} />
                <NavLink to={'/'} onClick={() => clickButton(button)}>
                </NavLink>
                <div className="mobile-home-button" onClick={()=>showProductcycle()}>
                    <text className="mobile-button-text">Product Cycle</text>
                    <div id="mobile-start-arrow" className="mobile-start-arrow-startimage"></div>
                </div>
                <div id='mobile-productcycle' className="start-animation-productcycle">
                    <Slideshow></Slideshow>
                </div>
                <NavLink to={'/'} onClick={() => clickButton(1)}>
                    <h1 id="desktopHome" className='title-down'>Home</h1>
                </NavLink>
            </div>
            <div id='home-right' className='right-container' onClick={() => setActive('right')}>
                <Slideshow></Slideshow>
            </div>
        </>
    );
}

export default Navbar;