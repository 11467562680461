export const aboutData = [
    {
        name: "Sarah Kim Harbarth",
        description: 'BA Industrial Design, CEO & Co-Founder',
        images: [ 'team/Sarah.jpg',
        ],
        category: "Team",
        link: "",
    },
    {
        name: "Taja Bornand",
        description: 'BSc International Management & MSc Sustainable Development, Administration Manager & Co-Founder',
        images: [ 'team/Taja.jpg',
        ],
        category: "Team",
        link: "",
    },
    {
        name: "Benjamin Kunz",
        description: 'BA Visual Communication, Visual Communication Lead',
        images: [ 'team/Benjamin.jpg',
        ],
        category: "NetworkCommunity",
        link: "",
    },
    {
        name: "Nils Repond",
        description: 'ETH Student, Material Research Assistant',
        images: [],
        category: "Team",
        link: "",
    },
    {
        name: "Thibaut Wenger",
        description: 'ZHdk Student, Marketing & Communication',
        images: [],
        category: "Team",
        link: "",
    },
    {
        name: "Prof. Dr. Christian Rytka",
        description: 'Material Science & Polymer Engineering, Advisor',
        images: [ 'team/Christian.jpg',
        ],
        category: "TeamIKTFHNW",
        link: "",
    },
    {
        name: "Simone Battaglia",
        description: 'BSc Mechanical Engineering, Research Lead',
        images: [ 'team/Simone.jpg',
        ],
        category: "TeamIKTFHNW",
        link: "",
    },
    {
        name: "Florian Frei",
        description: 'IT & Website Development',
        images: [],
        category: "NetworkCommunity",
        link: "",
    },
    {
        name: "Marie Klock",
        description: 'HGK Stundent, Social Media',
        images: [],
        category: "NetworkCommunity",
        link: "",
    },
    {
        name: "Dr. Christian Goldhahn",
        description: 'MSc Polymer Science & PhD Bio-Based Materials, CTO',
        images: [],
        category: "Team",
        link: "",
    },
    {
        name: "Institute of Polymer Processing and Sustainability (IKT)",
        description: 'FHNW, Windisch Brugg',
        images: [],
        category: "Partners",
        link: "https://www.fhnw.ch/en/about-fhnw/schools/school-of-engineering/institutes/institute-of-polymer-engineering",
    },
    {
        name: "Mehr als zwei",
        description: 'Zurich (CH)',
        images: [],
        category: "Partners",
        link: "https://mehralszwei.ch/",
    },
    {
        name: "Farm Öpfelfarm",
        description: 'Steinebrunn',
        images: [],
        category: "Partners",
        link: "https://www.oepfelfarm.ch/",
    },
    {
        title: "Project",
        highlight: "The cleantech start-up KUORI develops and sells circular, innovative materials that are produced with the help of food side streams such as banana peel or nut shells. In the spirit of the circular economy, KUORI materials should be both elastic, of biological origin and biodegradable. Their first application is in shoe soles, where they prevent the harmful abrasion of microplastics into soil and water.",
        text: "",
        images: ['project/Project.jpg','project/Project2.jpg','project/Project3.jpg','project/Project4.jpg','project/Project5.jpg'],
        category: "Project"
    },
]
