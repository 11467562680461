import React, {useState} from "react";
import './About.css'
import './Support.css'
import {aboutData} from "./aboutData";
import ReactHtmlParser from "react-html-parser";

export default function About(){
    const [images, setImages] = useState(0);
    const [currentImage, setCurrentImage] = useState(0);
    const [clicked, setClicked] = useState(null);

    var modal = document.getElementById("image-container-journal-mobile");
    var img = document.getElementById('image');
    var modalImg = document.getElementById("img01");

    function closeModal() {
        modal.style.display = "none";
        setClicked(null)
    }
    const toggleImages = index => {
        if(clicked === index) {
            setTimeout(()=>{setClicked(null)},1000)
        } else{
            if(clicked === null){
                setClicked(index)
            } else{
                setClicked(index)
                setCurrentImage(0)
                showPictures()
                setImages(-1)
            }
        }
        if(images === index){
            setCurrentImage(0)
            showPictures()
            return setImages(-1)
        }
        showPictures()
        return setImages(index)
    }
    function showPictures() {
        let className = document.getElementById('image-container-journal').className
        if(className === "image-container-show"){
            document.getElementById('aboutStart').classList.add('no-overlay')
            document.getElementById('image-container-journal').className = 'image-container-hide'
            setTimeout(() => {document.getElementById('aboutStart').classList.remove('no-overlay')}, 2000)
        } else {
            if( window.matchMedia("only screen and (max-width: 760px)").matches) {
                showMobileImage()
            }
            else{
                document.getElementById('aboutStart').classList.add('no-overlay')
                document.getElementById('image-container-journal').className = 'image-container-show'
                setTimeout(() => {document.getElementById('aboutStart').classList.remove('no-overlay')}, 1000)

            }
        }
    }
    function cycleImages(imageArray) {
        currentImage === imageArray.length - 1 ? setCurrentImage(0) : setCurrentImage(currentImage + 1)
    }

    function showMobileImage(){
        if(modal != null)
        {
            modal.style.display = "block";
        }
        else{
            modal = document.getElementById("image-container-journal-mobile");
            modal.style.display = "block";
        }
        img = document.getElementById('image');
        if(img != null)
        {
            modalImg.src = img.src;
        }
    }
    return (
        <>
            <div id='aboutStart' className='left-container active'>
                <div className="about-content">
                    <div className='donation-title'>Project </div>
                    {aboutData.map((data, index) => {
                        if(data.category === "Project"){
                        return <>
                            <div className="project-div">
                                <p className='aboutData-highlight'>{ReactHtmlParser(data.highlight)}</p>
                                <div id="interaktion" className={
                                    clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                </div>
                            </div>
                            <p className='aboutData-text'>{ReactHtmlParser(data.text)}</p>
                        </>}
                    })}
                    <div className='donation-title'>Core Team </div>
                    <div className='team-container'>
                        {aboutData.map((data, index) => {
                            if(data.category === "Team"){
                            return <>
                                <div className='team-item-info'>
                                    <div className='aboutData-highlight-Name'>{data.name}
                                        <div className='aboutData-text'>{data.description}</div>
                                    </div>
                                    <div id="interaktion" className={
                                        clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                    </div>
                                </div>

                            </>}
                        })}
                    </div>
                    <div className='donation-title'>Team IKT, FHNW</div>
                    <div className='team-container'>
                        {aboutData.map((data, index) => {
                            if(data.category === "TeamIKTFHNW"){
                            return <>
                                <div className='team-item-info'>
                                    <div className='aboutData-highlight-Name'>{data.name}
                                        <div className='aboutData-text'>{data.description}</div>
                                    </div>
                                    <div id="interaktion" className={
                                        clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                    </div>
                                </div>
                            </>}
                        })}
                    </div>
                    <div className='donation-title'>Team Freelance</div>
                    <div className='team-container'>
                        {aboutData.map((data, index) => {
                            if(data.category === "NetworkCommunity"){
                            return <>
                                <div className='team-item-info'>
                                    <div className='aboutData-highlight-Name'>{data.name}
                                        <div className='aboutData-text'>{data.description}</div>
                                    </div>
                                    <div id="interaktion" className={
                                        clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                    </div>
                                </div>
                            </>}
                        })}
                    </div>
                    <div className='donation-title'>Partners</div>
                    <div className='team-container'>
                        {aboutData.map((data, index) => {
                            if(data.category === "Partners"){
                            return <>
                                <div className='team-item-info'>
                                    <div className='aboutData-highlight-Name'>
                                        <a href={data.link} className="colorChange">{data.name}</a>
                                        <div className='aboutData-text'>{data.description}</div>
                                    </div>
                                    <div id="interaktion" className={
                                        clicked === index ? "arrow-inactive" : (data.images.length === 0 ? 'hidden' : 'arrow-active')} onClick={() => toggleImages(index)}>
                                    </div>
                                </div>
                            </>}
                        })}
                        <div className="foot"></div>
                    </div>
                </div>
            </div>
            <div id='image-container-journal' className="image-container-not-mobile">
                {aboutData.map((data, index) => {
                    if (clicked === index) {
                        const imgArray = data.images
                        if (imgArray.length > 0) {
                            return <>
                                <div className="flex">
                                    <img id="image" alt="" src={"images/teamimages/" + imgArray[currentImage]}
                                         onClick={() => cycleImages(imgArray)}/>
                                </div>
                            </>
                        }
                    }

                })}
            </div>
            <div id='image-container-journal-mobile' className="image-container-mobile">
                <span className="close" onClick={() => closeModal()}></span>
                {aboutData.map((data, index) => {
                    if (clicked === index) {
                        const imgArray = data.images
                        if (imgArray.length > 0) {
                            return <>
                                <div className="flex">
                                    <img id="image" alt="" src={"images/teamimages/" + imgArray[currentImage]}
                                         onClick={() => cycleImages(imgArray)}/>
                                </div>
                            </>
                        }
                        showMobileImage()
                    }

                })}
                <img className="modal-content" alt="" id="img01"/>
            </div>
            </>
    )

}