import React, {useState} from 'react';
import './slideshow.css';
import {NavLink} from 'react-router-dom';
const slideImages = [
    {
        title: 'Product Cycle',
        Image: 'images/bananaCycle/BananaCycle.svg'
    },
    {
        title: 'Fact 1',
        Image: 'images/bananaCycle/Fact1.svg'
    },
    {
        title: 'Cradle 2 Cradle',
        Image: 'images/bananaCycle/Cradle2CradleMaterial.svg'
    },
    {
        title: 'Fact 2',
        Image: 'images/bananaCycle/Fact2.svg'
    },
];

const Slideshow = () => {
    const [currentImage, setCurrentImage] = useState(0);
    function changeImage(i) {
        let newImage = currentImage+i;
        console.log(newImage)
        if (newImage === -1) {
            setCurrentImage(slideImages.length - 1)
        } else if (newImage ===slideImages.length) {
            setCurrentImage(0)
        } else {
            setCurrentImage(newImage)
        }

    }
    let closeButton = document.getElementById("closeButton")

    function hideCloseButton() {
        if(closeButton != null)
        {
            closeButton.style.display = 'none';
        }
    }
    function aniback() {
        if(document.getElementById('home-left').classList.contains('animate-left'))
        {
            hideCloseButton()
            document.getElementById('home-left').className = 'animateBack-left';
            document.getElementById('home-left').id = 'home-left';
            document.getElementById('home-right').className = 'animateBack-right';
            document.getElementById('home-right').id = 'home-right';
            document.getElementById('slide-show').classList.remove('not-visible');
            document.getElementById('home-right').classList.add('inactive')
            document.getElementsByClassName("animation-container").item(0).classList.remove('not-visible');
            document.getElementById("journal").style.display = "block";

            for(let i = 1; i < 5; i++)
            {
                let element = document.getElementsByClassName("btn-" + i + "-collapsed")
                if (element.length !== 0)
                {
                    element[0].classList.add("btn-" + i + "-default")
                    element[0].classList.remove("btn-" + i + "-collapsed")
                }
                else
                {
                    if(document.getElementsByClassName("btn-expanded").length > 0)
                    {
                        document.getElementsByClassName("btn-expanded")[0].classList.add("btn-" + i + "-default")
                        document.getElementsByClassName("btn-expanded")[0].classList.remove("btn-expanded")
                    }
                }
            }
        }
    }
    return (
        <>
            <div id="slide-show" class='slideshow-container'>
                <div className='slideshow-arrow slideshow-backarrow' onClick={() => changeImage(-1)} >
                    <div className='arrow-container' >
                        <img className='img-left-arrow' alt="" src={"images/arrows/arrow_left.svg"}/>
                    </div>
                </div>
                <div className='slideshow-arrow slideshow-nextarrow'onClick={() => changeImage(1)}>
                    <div className='arrow-container' >
                        <img className='img-right-arrow' alt="" src={"images/arrows/arrow_right.svg"}/>
                    </div>
                </div>
                <img className='slideshow' alt="" src={slideImages[currentImage].Image}></img>
            </div>
            <NavLink to={'/'} onClick={() => aniback()}>
                <h1 className='title-down'>{slideImages[currentImage].title}</h1>
            </NavLink>
        </>
    )
};

export default Slideshow;