export const journalData = [
    {
        title: "KUORI at the Circular Economy Entrepreneurs conference",
        text: 'Leaders of Swiss businesses and institutions came together to discuss how a successful transition towards a more circular'+
        'economy can be achieved and what small and large businesses as well as politics can contribute to a more sustainable future. It was inspiring to listen to renowned speakers'+
        'such as Thomas Vellacott (CEO WWF Schweiz), Ian Roberts (CTO Bühler Group) or current member of the Federal Council Simonetta Sommaruga and being able to exchange ideas and '+
        'experiences with other founders and start-up projects.',
        date: "22.09.2021",
        highlight: "Thanks to the Design Preis Schweiz we had the opportunity to exhibit KUORI at this year’s Circular Economy Entrepreneurs conference in the Kursaal in Bern. ",
        images: [
        ],
        links: [{},]
    },
    {
        title: 'KUORI is nominated for the Design Preis Schweiz!',
        text: "We are delighted to have been nominated in the \"Young Professionals\" category for this year's Design Preis Schweiz and are looking forward to the award ceremony on November 5th. Read more about it in the <a class='link-underline' href='https://www.creativehub.ch/de/blog/design-preis-schweiz-edition-21/'>blogarticle</a> by our supporter Creative Hub and watch our <a class='link-underline' href='https://designpreis.ch/project/kuori-a-biodegradable-alternative-material/'>nomination video</a>.",
        date: "15.09.2021",
        highlight: "",
        images: [
        ],
        links: [
            {'id': '[1]', 'link': 'https://www.creativehub.ch/de/blog/design-preis-schweiz-edition-21/'},
            {'id': '[2]', 'link': 'https://designpreis.ch/project/kuori-a-biodegradable-alternative-material/'},
        ]
    },
    {
        title: "Yeey! Financial support by the Gebert Rüf Foundation",
        text: `Thanks to this financial support, we are now able to start our next research phase as of September 1st 2021 and further develop our material. Together with the Institute for Polymer Processing and Sustainability of the Fachhochschule Nordwestschweiz we will work in the laboratories in Windisch and perform further trials and tests to create a first prototype. Read more about what is currently going on at KUORI in this <a class="link-underline" href='https://www.creativehub.ch/de/blog/kuori-von-der-bananenschale-zum-alternativmaterial/'>blog article</a> for the Creative Hub.`,
        date: "12.08.2021",
        highlight: "We are thrilled to announce that we have convinced the Gebert Rüf Foundation with our Pitch and have been awarded financial support for a pilot phase until February 2022! ",
        images: [
        ],
        links: [
            { 'id': '[1]', 'link':'https://www.creativehub.ch/de/blog/kuori-von-der-bananenschale-zum-alternativmaterial/'},
        ]
    },
]
