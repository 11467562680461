import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './fonts/GT-Zirkon-Bold.otf';

ReactDOM.render(


    <App />,
  document.getElementById('root')
);
