export const FinancialSupportData = [
    {
        title: "Gebert Rüf Stiftung",
        text: "First Ventures (CH)",
        img: "Gerbert_Ruf.svg",
        link: "https://www.grstiftung.ch/de/handlungsfelder/first_ventures.html",
    },
]
export const CoachingData = [
    {
        title: "Impact Hub Basel",
        mentor: "Incubator Edition 2022, Main mentor: Olena Bolger",
        img: "Impact_Hub_Basel.svg",
        link: "https://basel.impacthub.net/",
    },
    {
        title: "Innovation Basel",
        mentor: "StartUp Coaching (CH), Main mentor: Marilen Dürr",
        img: "Innovation_Basel.svg",
        link: "https://www.innovationbasel.ch/",
    },
    {
        title: "Creative Hub Switzerland",
        mentor: "Creative Business Coaching (CH), Main mentor: Michel Hueter",
        img: "Creative_Hub.png",
        link: "https://www.creativehub.ch/",
    },
    {
        title: "Grace",
        mentor: "Female Entrepreneurship Coaching (DE), Mentors: Jan Bathel, Peter Kowalsky, Tilo Hühn",
        img: "Grace.svg",
        link: "https://www.grace-accelerator.de/home.html",
    },

]
export const NominationsPricesAwardsData = [
    {
        title: "Won at the Gebert Rüf Foundation",
        subtitle: "First Ventures (CH)",
        text: "With «First Ventures» Gebert Rüf Stiftung promotes bachelor’s and master’s students of universities of applied sciences UAS who are developing an innovative business idea as their thesis topic. The support includes a financial contribution up to CHF 150,000 as well as an individually tailored coaching programme which will smooth the way to launching a startup. For this focus programme funds of CHF 1.5 million p.a. will be made available."
    },
    {
        title: "Design Prize Switzerland",
        subtitle: "Nomination as Young Professionals (CH)",
        text: "As a national competition, Design Prize Switzerland has been honouring the Swiss design industry for thirty years. Every second year, the participants and their projects are honoured on an equal level. Both the nominators and the members of the jury are independent and obliged solely to maintain the highest quality criteria. The award offers an internationally respected seal of quality which stands for the excellence of the Swiss design industry."
    },
    {
        title: "Green Concept Award (DE)",
        subtitle: "Selection 2021 & 2020 ",
        text: "Every year, the Green Future Club awards concepts for sustainable materials, products and services that are not yet on the market with the Green Concept Award.\n" +
            "It is aimed at students, young designers, researchers, start-ups and companies who want to showcase, further develop or market their concepts in order to change society and the economy.<br/>We support a selection of participants in the sense of an innovation agency with a variety of activities such as support with presentations and press work as well as introductions to the media, industry and investors."
    },
    {
        title: "Swiss Sustainability Challenge",
        subtitle: "Top 21 (CH)",
        text: "Young people have a whole host of ideas on issues in our society, whether they relate to social matters such as healthcare and education, the environment or infrastructural issues like public mobility and sustainable building. The Swiss Sustainability Challenge focuses on supporting students and young people in developing and implementing their ideas and projects, to help them succeed in their commitment to the good of their community."
    },
    {
        title: "German Design Award 2022, ",
        subtitle: "Nomination as Newcomer (DE)",
        text: "This year the German Design Awards celebrate their tenth anniversary! On this occasion, the presentation of the winners is themed \"How Designers Think\". Outstanding design means providing answers to the challenges of our time and thinking design further ahead. The top-class international jury honours these ground-breaking design achievements - and the German Design Awards make the design trends visible across all sectors in a glamorous setting."
    }

]
